import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MHeader from "./MHeader";

export default function Header() {
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState("");
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.width < 740) setViewMode("M");
      else setViewMode("D");
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.screen.width]);

  return (
    <>
      {viewMode === "M" ? (
        <MHeader />
      ) : (
        <div className="sticky top-0 left-0 w-full h-[80px] bg-[#265B8B] flex items-center justify-center z-[999]">
          <div className="w-full px-[20px] min-[1240px]:max-w-[1200px] min-[1240px]:px-0 h-full flex items-center justify-between">
            <div className="flex items-center gap-x-[8px] cursor-pointer">
              <img
                src="../assets/images/IC.png"
                className="w-[100px] object-contain h-[100px]"
                onClick={() => {
                  navigate("/");
                }}
              />

              <div className="flex flex-col -mt-[8px] justify-center items-center cursor-pointer">
                <span
                  className="flex justify-center text-[24px] font-bold"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <h1 className="text-[#84C7F2]">IC World</h1>
                  &nbsp;
                  <h1 className="text-[#ECECEB]"> Electronics</h1>
                </span>
                <h1
                  className="text-[16px] font-normal text-color-TXT-1"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  深 圳 艾 世 界 科 技 有 限 公 司
                </h1>
              </div>
            </div>
            <div className="flex items-center justify-center py-[10px] px-[10px] rounded-[5px] gap-x-[24px] text-[14px] font-semibold text-color-TXT">
              {window?.location?.pathname === "/" ? (
                <div className="flex flex-col justify-center items-center">
                  <span className={`text-color-A-2`} onClick={() => {}}>
                    Home
                  </span>
                  <span className="absolute mt-10 h-[3px] w-10 bg-color-BG rounded-md"></span>
                </div>
              ) : (
                <span
                  className={`cursor-pointer hover:text-white `}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Home
                </span>
              )}

              {window?.location?.pathname.includes("/product") ? (
                <div className="flex flex-col justify-center items-center">
                  <span className={`text-color-A-2`} onClick={() => {}}>
                    Products
                  </span>
                  <span className="absolute mt-10 h-[3px] w-10 bg-color-BG rounded-md"></span>
                </div>
              ) : (
                <span
                  className={`cursor-pointer hover:text-white `}
                  onClick={() => {
                    navigate("/products");
                  }}
                >
                  Products
                </span>
              )}
              {window?.location?.pathname.includes("/manufacturers") ? (
                <div className="flex flex-col justify-center items-center">
                  <span className={`text-color-A-2`} onClick={() => {}}>
                    Manufacturers
                  </span>
                  <span className="absolute mt-10 h-[3px] w-10 bg-color-BG rounded-md"></span>
                </div>
              ) : (
                <span
                  className={`cursor-pointer hover:text-white `}
                  onClick={() => {
                    navigate("/manufacturers");
                  }}
                >
                  Manufacturers
                </span>
              )}
              {window?.location?.pathname === "/about-us" ? (
                <div className="flex flex-col justify-center items-center">
                  <span className={`text-color-A-2`} onClick={() => {}}>
                    About Us
                  </span>
                  <span className="absolute mt-10 h-[3px] w-10 bg-color-BG rounded-md"></span>
                </div>
              ) : (
                <span
                  className={`cursor-pointer hover:text-white `}
                  onClick={() => {
                    navigate("/about-us");
                  }}
                >
                  About Us
                </span>
              )}
              {window?.location?.pathname === "/contact-us" ? (
                <div className="flex flex-col justify-center items-center">
                  <span className={`text-color-A-2`} onClick={() => {}}>
                    Contact Us
                  </span>
                  <span className="absolute mt-10 h-[3px] w-10 bg-color-BG rounded-md"></span>
                </div>
              ) : (
                <span
                  className={`cursor-pointer hover:text-white `}
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                >
                  Contact Us
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
